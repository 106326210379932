@primary-color: #2c6bff;

@shadow-1-down: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05),
  0 12px 48px 16px rgba(0, 0, 0, 0.03);

@screen-xs: 480px;
@screen-xs-min: @screen-xs;
// 👆 Extra small screen / phone

// 👇 Small screen / tablet
@screen-sm: 576px;
@screen-sm-min: @screen-sm;

// Medium screen / desktop
@screen-md: 768px;
@screen-md-min: @screen-md;

// Large screen / wide desktop
@screen-lg: 992px;
@screen-lg-min: @screen-lg;

// Extra large screen / full hd
@screen-xl: 1200px;
@screen-xl-min: @screen-xl;

// Extra extra large screen / large desktop
@screen-xxl: 1600px;
@screen-xxl-min: @screen-xxl;

html,
body {
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

input[type='number'] {
  -moz-appearance: textfield !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 99999s !important;
  -webkit-transition: color 99999s ease-out, background-color 99999s ease-out !important;
}

.fx__btn {
  margin-top: 35px;
  font-size: 18px !important;
  height: 56px !important;
  transition: all 0.3s ease-in-out;
  border-radius: 12px !important;

  &:disabled {
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.25) !important;
    background: rgba(0, 0, 0, 0.04) !important;
  }
}

.fx__login-captcha-btn {
  position: absolute !important;
  right: 0;
  z-index: 10;
}

.fle-ai .verify-bar-area .verify-left-bar {
  background: #7e31c9 !important;
}

.mc-theme-class .verify-bar-area .verify-left-bar {
  background: #00b45b !important;
}

